import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "index",
      component: () => import("@/pages/index.vue"),
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/pages/Home.vue"),
    },
    {
      path: "/reimburse",
      name: "reimburse",
      component: () => import("@/pages/reimburse/index.vue"),
    },
    {
      path: "/historyReim",
      name: "historyReim",
      component: () => import("@/pages/reimburse/historyReim.vue"),
    },
    {
      path: "/approvalRei",
      name: "approvalRei",
      // meta: {
      //   keepAlive: true, // 需要被缓存
      // },
      component: () => import("@/pages/reimburse/approvalRei.vue"),
    },
    {
      path: "/viewReim",
      name: "viewReim",
      component: () => import("@/pages/reimburse/viewReim.vue"),
    },
    {
      path: "/withdrawal",
      name: "withdrawal",
      // meta: {
      //   keepAlive: true, // 需要被缓存
      // },
      component: () => import("@/pages/withdrawal/index.vue"),
    },
    {
      path: "/withdrawalView",
      name: "withdrawalView",
      component: () => import("@/pages/withdrawal/withdrawalView.vue"),
    },
    {
      path: "/failureRetryView",
      name: "failureRetryView",
      component: () => import("@/pages/withdrawal/failureRetryView.vue"),
    },
    {
      path: "/rebateView",
      name: "rebateView",
      component: () => import("@/pages/withdrawal/rebateView.vue"),
    },
    {
      path: "/contract",
      name: "contract",
      component: () => import("@/pages/contract/index.vue"),
    },
    {
      path: "/invoice",
      name: "invoice",
      component: () => import("@/pages/invoice/index.vue"),
    },
    {
      path: "/received",
      name: "received",
      component: () => import("@/pages/received/index.vue"),
    },
    {
      path: "/personInfo",
      name: "personInfo",
      component: () => import("@/pages/personInfo/index.vue"),
    },
    // 我的合同
    {
      path: "/contractProgress",
      name: "contractProgress",
      component: () => import("@/pages/contractProgress/index.vue"),
    },
    // 销售合同详情
    {
      path: "/contractView",
      name: "contractView",
      component: () => import("@/pages/contractProgress/contractView.vue"),
    },
    // 销售协议详情
    {
      path: "/agreementView",
      name: "agreementView",
      component: () => import("@/pages/contractProgress/agreementView.vue"),
    },
    // 采购合同详情
    {
      path: "/contractBuy",
      name: "contractBuy",
      component: () => import("@/pages/contractProgress/contractBuy.vue"),
    },
    // 到款录入
    {
      path: "/automatic",
      name: "automatic",
      component: () => import("@/pages/financialManagement/automatic.vue"),
    },
    // 发票审批
    {
      path: "/invoiceApprove",
      name: "invoiceApprove",
      component: () => import("@/pages/invoiceApprove/index.vue"),
    },
    {
      path: "/viewInvoice",
      name: "viewInvoice",
      component: () => import("@/pages/invoiceApprove/viewInvoice.vue"),
    },
    // 其他审批
    {
      path: "/otherApproval",
      name: "otherApproval",
      component: () => import("@/pages/otherApproval/index.vue"),
    },
    // 其他审批
    {
      path: "/productDetails",
      name: "productDetails",
      component: () => import("@/pages/otherApproval/productDetails.vue"),
    },
    {
      path: "/companyManagement",
      name: "companyManagement",
      component: () => import("@/pages/companyManagement/index.vue"),
    },
    {
      path: "/companyDetail",
      name: "companyDetail",
      component: () => import("@/pages/companyManagement/companyDetail.vue"),
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   next();
// });

export default router;
