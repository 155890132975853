import Vue from "vue";
import { Toast } from "vant";
import axios from "axios";
// import { mapState, mapMutations } from "vuex";
import Vuex from "vuex";
Vue.use(Toast, Vuex);
// import store from "@/store/index.js";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// axios.defaults.headers.common['token'] = sessionStorage.getItem('token');

const service = axios.create({
  // baseURL: process.env.VUE_APP_URL,
  //正式
  baseURL: "https://erpapi.zanhua.com.cn",
  // 测试
  // baseURL: "https://erpapitest.zanhua.com.cn",
  timeout: 30000,
});

let loading;       
function startLoading() {  
  loading = Toast.loading({
    message: '加载中...',
    forbidClick: true,
  })
};
 
function endLoading() {   
  loading.clear()
};

service.interceptors.request.use(
  (config) => {
    startLoading();
    config.headers.common["token"] = localStorage.getItem("token");
    // config.headers['token'] = '3A157D3A4B2C3639EF02BFBAD0BF4667';
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    endLoading();
    const success = res.data.success;
    if (success) {
      return res.data;
    } else {
      // 不成功加错误提示
      // confirm(res.data.resultMsg);
      // store.commit("setApiError", res.data.resultMsg);
      // return Promise.reject(res.data);
      return res.data;
    }
  },
  (error) => {
    console.log(error);
    if (String(error).includes("timeout")) {
      // Toast.fail('请求超时');
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
