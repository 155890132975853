<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<style lang="scss" >
.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
<script>
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      test: (state) => state.test,
    }),
  },
  created() {
    this.$store.commit("setTest", "haha");
  },
};
</script>
