import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  // 初始化数据，等待获取
  state: {
    test: "",
    apiError: "",
  },
  // 更新数据
  mutations: {
    setTest(state, payload) {
      state.test = payload;
    },
    setApiError(state, data) {
      state.apiError = data;
    },
  },
});

export default store;
